<template>
    <v-row justify="center">
        <dialog-post ref="dialog_post"></dialog-post>

        <v-dialog v-model="dialog" width="500px" maxWidth="500px">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs"
                       v-on="on"
                       x-large
                       depressed
                       :disabled="!validbutton"
                       color="success">
                    {{$t('create-post.create-post')}}
                </v-btn>
            </template>
            <v-card flat class="disable-select">
                <dragger class="mr-4"></dragger>
                <v-card-title class="text-break">{{$t('create-post.similar-posts')}}</v-card-title>

                <v-card-text>{{posts.length > 0 ? $t('create-post.similar-posts-found') :
                    $t('create-post.no-similar-posts-found')}}
                </v-card-text>

                <div class="text-center mt-3 mb-3">
                    <loading-circle v-if="loading"></loading-circle>
                </div>

                <div>

                    <v-card flat v-for="post in posts"
                            :key="post.ID"
                            color="grey darken-3"
                            class="mx-3 pa-2"
                            @click="open_dialog(post.ID)">
                        <div class="dont-break-out">
                            {{post.title}}
                        </div>
                        <div class="mt-1">
                            <post-tags :post="post"></post-tags>
                        </div>
                    </v-card>


                    <v-col class="text-center">

                        <!--Hide captcha when user is superadmin or its disabled-->
                        <vue-hcaptcha v-if="!$variables.v.debug_disable_captcha
                                               && !$root.check_mod()
                                                && !captcha_solved_delay"
                                      :key="unlock_captcha"
                                      :sitekey="$variables.v.hcaptcha_sitekey"
                                      @verify="captcha_solved"
                                      @expired="captcha_success=false"
                                      @error="captcha_success=false"
                                      theme="dark"></vue-hcaptcha>

                        <v-btn x-large
                               :disabled="loading || ((!$variables.v.debug_disable_captcha && !$root.check_mod()) && (!captcha_success && !captcha_solved_delay) )"
                               color="success"
                               depressed
                               :key="unlock_create_post"
                               @click.once="create_post">
                            {{$t('create-post.create-post')}}
                        </v-btn>
                    </v-col>
                </div>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        props  : ['validbutton', 'post_title', 'post_offer_url', 'offer_type'],
        data() {
            return {
                dialog              : false,
                loading             : false,
                captcha_success     : false,
                captcha_solved_delay: false,
                unlock_captcha      : 100000,
                unlock_create_post  : 0,
                posts               : [],
            }
        },
        watch  : {
            dialog() {
                this.captcha_success = false;
                if (this.dialog) {
                    this.captcha_solved_delay = new Date - Date.parse(this.$store.last_captcha_solved) < 1000 * 60 * Number(this.$variables.v.create_post_captcha_delay_in_min);
                    this.get_posts();
                }
                else {
                    this.captcha_solved_delay = false;
                    this.posts                = [];
                }
                this.unlock_captcha++;
            },
        },
        methods: {
            captcha_solved() {
                this.captcha_success            = true;
                this.$store.last_captcha_solved = new Date();
            },
            create_post() {
                // When Captcha completed or the delay of the last captcha still running
                if (this.$root.check_captcha(this.captcha_success || this.captcha_solved_delay)) {
                    this.dialog = false;
                    this.$emit('confirmed');
                    this.unlock_create_post++;
                }
            },
            async get_posts() {
                this.loading = true;
                this.posts   = await this.func.ax("/php/getter.php", this.build_postdata());
                this.loading = false;
            },
            build_postdata() {
                return {
                    func             : "get_similar_posts",
                    userid           : this.$user.user.id,
                    max_similar_shown: this.$variables.v.post_create_max_similar_shown,
                    offer_type       : this.offer_type,
                    search_title     : this.post_title.trim(),
                    search_url       : this.post_offer_url.replace(/^https?:\/\//, '') // Remove http(s)
                };
            },
            open_dialog(id) {
                this.$refs.dialog_post.open_dialog(id); // Bis zur der post-view Seite
            }
        },
    }
</script>